<template>
  <transition name="fade">
    <v-dialog v-model="error" max-width="300" class="rounded-xl">
      <v-card class="pb-5" rounded="xl">
        <v-card-title
          class="
            text-h5
            teal--text
            text--darken-3
            d-flex
            justify-space-between
            pt-0
            pe-0
          "
        >
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                $emit('exitError')
              }
            "
          />
        </v-card-title>

        <v-card-text class="text-center">
          <v-icon
            v-if="settings.graphics && settings.graphics.mode == 'low'"
            x-large
            >mdi-alert</v-icon
          >
          <v-img
            v-else
            :src="require('@/assets/island/loadingError.png')"
          ></v-img>
          <p
            class="
              red
              lighten-5
              red--text
              text-center
              mx-3
              my-3
              px-5
              py-5
              rounded-xl
              text-h6
            "
          >
            {{ message }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
let audio = new Audio(require("@/assets/sounds/sfx_oops.mp3"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["message", "error"],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    audio.volume = this.settings.audio.sfx * this.settings.audio.master;
    audio.play();  
  },
};
</script>
